import React from 'react'
import PositionTile from './PositionTile'

function PositionMapper({positions, positionClickFunction}) {

  return (
    <div>
        {positions && Array.isArray(positions) && positions.map(positionData => (
            <PositionTile positionData={positionData} positionClickFunction={positionClickFunction}></PositionTile>
        ))}
    </div>
  )
}
PositionMapper.defaultProps = {
    positions: []
}
export default PositionMapper